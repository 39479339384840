<style scoped="">
    .service-name {
        width: 320px;
        text-align: center;
    }
    .service-number {
        width: 100px;
        text-align: center;
    }
</style>

<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="panel">
                    <div class="panel-body">
                        <h3 class="panel-title"><img :src="require('@/assets/images/icons/services.svg')" class="panel-icon" alt=""> Cài đặt dịch vụ</h3>

                        <form action="" @submit.prevent="updateSettings">
                            <div class="row">
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Giảm giá Thành Viên ( {{ settings.discount_user }}% ) :</label>
                                        <input type="number" class="form-control" v-model="settings.discount_user">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Giảm giá CTV ( {{ settings.discount_cob }}% ) :</label>
                                        <input type="number" class="form-control" v-model="settings.discount_cob">
                                    </div>
                                </div>
                                <div class="col-lg-4">
                                    <div class="form-group">
                                        <label>Giảm giá Đại Lý ( {{ settings.discount_agency }}% ) :</label>
                                        <input type="number" class="form-control" v-model="settings.discount_agency">
                                    </div>
                                </div>
                            </div>

                            <div class="form-group">
                                <label>Phần trăm giá dịch vụ ( {{ settings.price_percent }}% ) :</label>
                                <input type="number" class="form-control" v-model="settings.price_percent" @input="pricePercent">
                                <div class="form-check form-switch pt-2">
                                    <label class="form-check-label" for="AutoUpPercent">
                                        <input class="form-check-input" type="checkbox" id="AutoUpPercent" :checked="settings.auto_up_price == 1" @click="AutoUpPercent($event.target.checked)"> 
                                        Tự động nâng <font class="text-success">{{ settings.price_percent }}%</font> giá gốc khi có dịch vụ mới từ <a :href="'//' + $store.state.owner_data.site_name + '/price'" target="_blank" class="au">{{ $store.state.owner_data.site_name.toUpperCase() }}</a>?
                                    </label>
                                </div>
                            </div>
                            <button class="btn b-success">Lưu thiết lập</button>
                        </form>

                        <hr>


                        <button class="btn b-info btn-block mb-3" @click="getServices">Lấy giá gốc từ {{ $store.state.owner_data.site_name.toUpperCase() }} và nâng {{ settings.price_percent }}%</button>

                        <br>

                        <ul class="nav nav-pills nav-justified" role="tablist">
                            <li class="nav-item" v-for="(provider, k) in Object.keys(services)" @click.prevent="tabs(provider)">
                                <a class="nav-link" :class="{active: k === 0}" data-toggle="tab" :href="'#' + provider" role="tab" aria-selected="true">
                                    <img :src="require('@/assets/images/service/' + provider.replace('_buff', '') + '.png')" alt="" style="height: 20px;"> <span class="d-none d-md-inline-block">{{ provider | providerText }}</span>
                                </a>
                            </li>
                        </ul>

                        <br>

                        <form action="" @submit.prevent="submit">
                            <div class="tab-content">
                                <div class="tab-pane" :class="{active: index === 0}" :id="k" role="tabpanel" v-for="(serviceLists, k, index) in services">
                                    <div class="table-responsive">
                                        <table class="table text-center table-bordered table-hover">
                                            <thead>
                                                <tr>
                                                    <!-- <th>Trạng thái</th> -->
                                                    <th>Dịch vụ</th>
                                                    <th>Giá KBH</th>
                                                    <th>Giá BH</th>
                                                    <th>Lọc giới tính ( % )</th>
                                                    <th>Min</th>
                                                    <th>Max</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(sv) in serviceLists">
                                                    <!-- <td>
                                                        <div class="form-switch">
                                                            <input class="form-check-input" type="checkbox" id="xx" checked="">
                                                            <label class="form-check-label" for="xx"></label>
                                                        </div>
                                                    </td> -->
                                                    <td>
                                                        <input type="text" class="form-control service-name" v-model="sv.name">
                                                    </td>
                                                    <td>
                                                        <vue-numeric class="form-control service-number" v-model="sv.price"v-bind:minus="false" separator=","></vue-numeric>
                                                    </td>
                                                    <td>
                                                        <vue-numeric class="form-control service-number" v-bind:minus="false" v-model="sv.price_warranty" separator="," v-if="sv.has_price_warranty > 0"></vue-numeric>
                                                    </td>
                                                    <td>
                                                        <vue-numeric class="form-control service-number" v-bind:minus="false" v-model="sv.filter_percent" separator="," v-if="sv.has_filter_percent > 0"></vue-numeric>
                                                    </td>
                                                    <td>
                                                        <vue-numeric class="form-control service-number" v-bind:minus="false" v-model="sv.min" separator=","></vue-numeric>
                                                    </td>
                                                    <td>
                                                        <vue-numeric class="form-control service-number" v-bind:minus="false" v-model="sv.max" separator=","></vue-numeric>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <button class="btn b-success btn-block mt-3">Lưu bảng giá</button>
                        </form>


                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>


    export default {
        props: ['settings', 'tabs'],
        data () {
            return {
                //price_percent: this.settings.price_percent,
                customService: {},
            }
        },
        created () {
            this.getServicesSite();
        },
        computed: {
            services () {
                /*let v = {};
                let sv = this.customService;
                if (this.price_percent > 0) {
                    Object.keys(sv).filter(key => {
                        v[key] = sv[key].filter(x => {
                            x.price = this.customPrice(x.price);
                            x.price_warranty = this.customPrice(x.price_warranty);
                            return x;
                        });
                    });
                }*/
                return this.customService;
            },
            price_percent () {
                return this.settings.price_percent;
            }
        },
        methods: {
            customPrice (p) {
                return Math.floor(p + p * this.price_percent / 100);
            },
            pricePercent () {

            },
            getServices () {
                this.$http.post('admin/services?action=api_lists', {price_percent: this.price_percent}).then(res => {
                    this.customService = res.body.data;
                    this.$swal('Thông báo', res.body.message, (res.body.status == 1 ? 'success' : 'error'));
                });
            },
            getServicesSite () {
                this.$http.post('admin/services?action=lists').then(res => {
                    this.customService = res.body;
                });
            },
            submit () {
                this.$swal({
                    title: 'Chắc chắn?',
                    text: 'Bạn đã chắc chắn thiết lập kỹ bảng giá và muốn cập nhật?',
                    imageUrl: require('@/assets/images/icons/services.svg'),
                    imageHeight: '100px',
                    showCancelButton: 1,
                    cancelButtonText: 'Kiểm tra lại',
                    confirmButtonText: 'Cập nhật'
                }).then((isConfirm) => {
                    if (isConfirm.value) {
                        this.$http.post('admin/services?action=update', {services: JSON.stringify(this.services)}).then(res => {
                            this.$swal('Thông báo', res.body.message, (res.body.status == 1 ? 'success' : 'error'));
                        });
                    }
                });
                //console.log(this.services);
            },
            updateSettings () {
                this.$http.post('admin/settings', this.settings).then(res => {
                    this.$swal('Thông báo', res.body.message, (res.body.status == 1 ? 'success' : 'error'));
                });
            },
            AutoUpPercent (checked) {
                this.settings.auto_up_price = (checked == true ? 1 : 0);
            }
        }
    }
</script>